import logo from './logo.svg';

import Home from "./Pages/Home.js";
import AboutMe from "./Pages/AboutMe.js";
import Register from "./Pages/Register.js";
import Nav from "./Comps/Nav.js";
import Login from "./Pages/Login.js";
import Project from "./Pages/Project.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { EaselPlugin } from "gsap/EaselPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import Lenis from 'lenis'
gsap.registerPlugin( ScrollTrigger);



function App() {

	const lenis = new Lenis()

    lenis.on('scroll', ScrollTrigger.update)

    gsap.ticker.add((time)=>{
    lenis.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)

  return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/AboutMe" element={<AboutMe />} />
					<Route path="/Nav" element={<Nav />} />
					{/* <Route path="/Login" element={<Login />} /> */}
					{/* <Route path="/register" element={<Register />} /> */}
					<Route path="/Project" element={<Project />} />
					
				</Routes>
			</Router>

			
		</div>
  );
}

export default App;
