import React from "react";
import Ren from "../Comps/ani.js";
import Scribb from "./flower1.js";
import {projs} from "../Comps/proj.js";

const Cards = () => {



    return(
      
        <div id="proj">


        <div className="card" >
        <img src={require("../Images/sphere/1shot.png")} 
              id="serverScreen2" className='interactable projCard'
              alt="loading..."  onClick={(e) =>
              (projs("serverSphere"))} />

          <div className="card-content">
          <small>ServerSphere</small>
            <p>WEB APP</p>
            <div id="chips">
                <div id="chip">Mongo</div>
                <div id="chip">Express</div>
                <div id="chip">React</div>
                <div id="chip">Node</div>

            </div>
        </div>
        </div>

        
        <div className="card">
        <img src={require("../Images/lounge/long.png")} 
          id="serverScreen" 
          className='interactable projCard' 
          alt="loading..." 
          onClick={(e) =>
            (projs("TheLounge"))} />


          <div className="card-content">
          <small>The Lounge</small>
            <p className='small'>WEB APP</p>
            <div id="chips">
                <div id="chip">HandleBars</div>
                <div id="chip">Express</div>
                <div id="chip">SQL</div>
                <div id="chip">Node</div>

            </div>
          </div>
        </div>


        <div className="card">
          <img src={require("../Images/cheep/shot1.png")}
           id="serverScreen" className='interactable projCard' 
           alt="loading..." 
           onClick={(e) =>
            (projs("Cheep"))} />
          <div className="card-content">
            <small>SFSU Tutor App</small>
            <p>WEB APP</p>
            <div id="chips">
                <div id="chip">AWS</div>
                <div id="chip">SQL</div>
                <div id="chip">Express</div>
                <div id="chip">React</div>
                <div id="chip">Node</div>

            </div>
          </div>
        </div>


        <div className="card"id = "adjust" >
          <img src={require("../Images/bot/long.png")} id="serverScreen" className='interactable projCard' alt="loading..." onClick={(e) =>
              (projs("Dbot"))}  />
    
          <div className="card-content">
            <small>BioTech Research Discord Bot</small>
            <p>Medical Database Bot</p>
              <div id="chips">
                <div id="chip">AWS</div>
                <div id="chip">SQL</div>
                <div id="chip">Python</div>
                <div id="chip">Discord</div>
            </div>
          </div>
          
        </div>


        <div className="card">
          <img src={require("../Images/linux/shot1.png")} 
          id="serverScreen" 
          className='interactable projCard' 
          alt="loading..." 
          onClick={(e) =>
            (projs("DeviceDriver"))} />
          <div className="card-content">
            <small>Device Driver</small>
            <p>Encryption Driver & File System</p>
            <div id="chips">
                <div id="chip">VirtualBox</div>
                <div id="chip">Ubuntu</div>
                <div id="chip">C</div>
            </div>
          </div>
        </div>


        <div className="card"id = "adjust2" >
          <img src={require("../Images/pac/all.png")} 
          id="serverScreen" 
          className='interactable projCard' 
          alt="loading..." 
          onClick={(e) =>
            (projs("Pac"))} />
          <div className="card-content">
            <small>Pacman AI</small>
            <p>Algorithm Design</p>
            <div id="chips">
                <div id="chip">Python</div>
                <div id="chip">Anaconda CLI</div>
            </div>
          </div>
        </div>
        <div className="card"id = "3d" >
            <div>
                <Ren/>
            </div>
          <div className="card-content">
            <small>Zoro's Sword</small>
            <p>Wado Ichimonji</p>
            <div id="chips">
                <div id="chip">Three.js</div>
                <div id="chip">React-three-fiber</div>
                <div id="chip">ZORO</div>
                <div id="chip">Blender </div>
            </div>
          </div>
        </div>

     

      </div>
    );



}

export default Cards;