import React, { useState, useEffect, Fragment } from 'react';
import Nav from "../Comps/Nav.js";
import Ren from "../Comps/ani.js";
import Gsaps from "../Comps/move.js";
import LsbBar from "../Comps/lsbBar.js";
import Footer from "../Comps/footer.js";
import { projects, projs } from "../Comps/proj.js";
import ImageViewer from 'react-simple-image-viewer';
import { Canvas } from '@react-three/fiber'
import SideContact from "../Comps/contactMenu.js";

import { useLocation } from 'react-router-dom';
function Project() {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

 useEffect(() => {
    const handleScroll = () => {
      const scrollingElement = document.querySelector('.scrolling');
      const scrollTop = window.scrollY;

      if (scrollTop < 685) {
        scrollingElement.classList.remove('fixed');
      } else {
        scrollingElement.classList.add('fixed');
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const openImageViewer = (index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const user = JSON.parse(sessionStorage.getItem('user'));
  let isLoggedIn = false;

  //cursorPointer();

  if (user) {
    console.log(user);
    isLoggedIn = true;
  }
  const list = ["serverSphere", "TheLounge", "Cheep", "DeviceDriver", "Dbot", "Pac"];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectKey = queryParams.get('proj');
  const [currentProject, setCurrentProject] = useState(null);
  useEffect(() => {
    if (projectKey && projects[projectKey]) {
      setCurrentProject(projects[projectKey]);
    }
  }, [projectKey]);
  const navigateProject = (direction) => {
    const currentIndex = list.indexOf(projectKey);
    let newIndex;

    if (direction === 'next') {
      newIndex = (currentIndex + 1) % list.length;
    } else if (direction === 'prev') {
      newIndex = (currentIndex - 1 + list.length) % list.length;
    }

    const newProjectKey = list[newIndex];
    projs(newProjectKey);
  };

  if (!currentProject) return <div>Loading...</div>;

  return (
    <Fragment>
      <SideContact/>
      <div className="Main-Cont" >


        <header className="site-header">
          <Nav />
        </header>
        <aside className="site-lsb">
          <LsbBar />
        </aside>

        <div className="site-content">
          <div id="projContainer"></div>
          {currentProject ? (
            <div className="project-details">
              <div className="project-card">
                <div className="project-imgHolder">
                  <img
                    src={currentProject.imgSrc}
                    className="project-image"
                    alt="project"
                    onClick={() => openImageViewer(0)}
                  />
                </div>
                <div className="project-content">
                  <div className="navNti">
                    <h1 className="project-title scrolling">{currentProject.title}</h1>
                    <div className="">
                      <div id="projButts"className="butt-holder">
                        <div className="butt " onClick={() => navigateProject('prev')}>Prev</div>
                        <div className="butt" onClick={() => navigateProject('next')}>Next</div>
                      </div>
                    </div>
                  </div>
                  <div id="projDes">
                    <div className="description-row proj-center">
                      <h3>Overview</h3>
                      <p className="project-description monst ">{currentProject.description}</p>
                    </div>

                    <div className="">
                      <h3 className="proj-center" >Role</h3>
                      <p className="proj-center monst ">{currentProject.Role}</p>
                    </div>
                    <div >
                      <h3 className="proj-center">Tools</h3>
                      <p className="proj-center monst ">{currentProject.tools}</p>
                    </div>
                    <div className="description-row">
                      <h3 className="proj-center">What I did</h3>
                      <p className=" monst">{currentProject.WID}</p>
                    </div>
                  </div>

                  <div id="project-extras">
                    {currentProject.extras.map((src, index) => (
                      <img
                        src={src}
                        alt={`extra-${index}`}
                        key={index}
                        onClick={() => openImageViewer(index)}
                      />
                    ))}
                  <div className="project-navigation">
                      <div className="butt-holder">
                        <div className="butt " onClick={() => navigateProject('prev')}>Prev</div>
                        <div className="butt" onClick={() => navigateProject('next')}>Next</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>Loading project details...</p>
          )}

          {isViewerOpen && (
            <ImageViewer
              src={[currentProject.imgSrc, ...currentProject.extras]}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
        </div>

        <div></div>
        <div className="site-footer">
          <Footer />
        </div>
      </div>
    </Fragment>
  );
}

export default Project;
