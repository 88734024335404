import React, { Fragment,useEffect } from 'react';

const links = (where)=> {
    if(where == "git"){
        window.open("https://www.github.com/Esau4119", "_blank");
    }else if(where == "email"){
      //Contact Page
    }else if(where == "linkedIn"){
      window.open("https://www.linkedin.com/in/esaubm", "_blank");
    }else if(where == "me"){
        window.location.href = "/AboutMe";
    }
    else if(where == "home"){
        window.location.href = "/";
    }
    else if(where == "contact"){
        window.location.href = "/Contact";
    }
    else if(where == "login"){
        window.location.href = "/Login";
    }

}

export default links;