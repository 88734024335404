import React, { Fragment,useEffect } from 'react';

const cloneElementMultipleTimes = (element, times) => {
    const clonedElements = [];
    for (let i = 0; i < times; i++) {
        clonedElements.push(React.cloneElement(element, { key: `${element.key}-${i}` }));
    }
    return clonedElements;
};

export default cloneElementMultipleTimes;