import React, { useEffect } from 'react';

function CursorPointer() {

  useEffect(() => {
    const blob = document.querySelector(".blob");
    const blobName = document.querySelector(".blobName");
    var interac ;
      window.onmousemove = e => {
          const interactable = e.target.closest(".interactable"),
                interacting = interactable !==null;
          
        if (interacting) {
          interac = true;
          blob.setAttribute('id', "");
          blobName.setAttribute('id', "");

          // Change text based on the type of interactable element
          if (interactable.classList.contains("projCard")) {
              blobName.textContent = "PROJ";
          } else if (interactable.classList.contains("email")) {
              blobName.textContent = "MSG";
          } else if (interactable.classList.contains("so")) {
              blobName.textContent = "LINK";
          }
          
      } else {
          interac = false;
          blob.setAttribute('id', "not");
          blobName.setAttribute('id', "not");

      }
      }

    const handlePointerMove = (event) => {
      if (blob && interac) {
        const { clientX: x, clientY: y } = event;
        blob.animate({
          left: `${x+45}px`,
          top: `${y+20}px`
        }, { duration: 1500, fill: "forwards" });
        blobName.animate({
         left: `${x+45}px`,
          top: `${y+20}px`
        }, { duration: 1200, fill: "forwards" });
      }
    };

    document.body.addEventListener("pointermove", handlePointerMove);

    return () => {
      document.body.removeEventListener("pointermove", handlePointerMove);
    };
  }, []);

  return null; // This component doesn't render anything
}

export default CursorPointer;
