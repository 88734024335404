import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import wadoModel from '../models/wado.glb';

// Model Component
function Model() {
  const gltf = useGLTF(wadoModel);
  const modelRef = useRef();
  
  // Adding rotation effect
  useFrame((state, delta) => {
    if (modelRef.current) {
      modelRef.current.rotation.y += delta * 0.5;  // Adjust the rotation speed
    }
  });

  return <primitive ref={modelRef} object={gltf.scene} scale={[2, 2, 2]} position={[0, -20, 0]} />;
}

// Scene Component
function Scene({ onScroll, cameraPositions, currentCameraIndex }) {
  const cameraRef = useRef();
  const controlsRef = useRef();

  useFrame(() => {
    const { position, target } = cameraPositions[currentCameraIndex];
    if (cameraRef.current && controlsRef.current) {
      cameraRef.current.position.set(position[0], position[1], position[2]);
      controlsRef.current.target.set(target[0], target[1], target[2]);
      cameraRef.current.lookAt(target[0], target[1], target[2]);
      controlsRef.current.update();
    }
  });

  useEffect(() => {
    // Adding the scroll listener
    window.addEventListener('wheel', onScroll);
    return () => {
      window.removeEventListener('wheel', onScroll);
    };
  }, [onScroll]);

  return (
    <>
      <ambientLight intensity={0.4} />
      <spotLight position={[10, 20, 10]} angle={Math.PI / 6} penumbra={0.3} intensity={1.5} castShadow />
      <OrbitControls ref={controlsRef} enableDamping dampingFactor={0.5} enableZoom={false} enablePan={false} />
      <Model />
    </>
  );
}

// Main Render Component
function Ren() {
  const cameraPositions = [
    { position: [-13.46, -15.60, 0.60], target: [0, -20, 0] },
    { position: [15, -15.60, 10], target: [0, 0, 0] },
    { position: [-5, 30, -10], target: [0, 0, 0] },
    { position: [-15, 10, 10], target: [0, 0, 0] },
    { position: [-13.46, -15.60, 0.60], target: [0, -20, 0] },
  ];

  const [currentCameraIndex, setCurrentCameraIndex] = useState(0);

  const handleScroll = (event) => {
    if (event.deltaY > 0) {
      setCurrentCameraIndex((prev) => (prev + 1) % cameraPositions.length);
    } else {
      setCurrentCameraIndex((prev) => (prev - 1 + cameraPositions.length) % cameraPositions.length);
    }
  };

  return (
    <Canvas
      onWheel={handleScroll}
      camera={{ position: cameraPositions[currentCameraIndex].position, fov: 120 }}
    >
      <Scene onScroll={handleScroll} cameraPositions={cameraPositions} currentCameraIndex={currentCameraIndex} />
    </Canvas>
  );
}

export default Ren;
