import React, { Fragment } from "react";
import links from "../Comps/links.js";
import { projs } from "../Comps/proj.js";
import SideContact from "./contactMenu.js";


const Lmb = () => {

    return (
        
        <div className="txts">

            <div id="side-menu" className="side-menu">
                <h2>

                    <button className="button sidebar-button " onClick={(e) => links("home")}>
                        Home

                    </button>
                    <button className="button sidebar-button " onClick={(e) => links("me")}>
                        Journal

                    </button>
                    <button className="button sidebar-button" onClick={(e) => {
                        // Toggle side menu visibility
                        const sideMenu = document.getElementById('side-contact');
                        sideMenu.classList.toggle('visible');
                        const sidedMenu = document.getElementById('side-menu');
                        sidedMenu.classList.toggle('visible');
                        const menuButton = document.getElementById('menu');
                        menuButton.classList.remove('opened');
                        menuButton.setAttribute('aria-expanded', 'false');
                        
                    }}>
                        Contact
                    </button>

                    <button className="abchan button sidebar-button " onClick={(e) => projs("serverSphere")}>
                        Projects
                    </button>

                    <button className="button sidebar-button" onClick={(e) => links("git")}>
                        Github
                    </button>

                    <button className="button sidebar-button" onClick={(e) => links("linkedIn")}>
                        LinkedIn
                    </button>
                    <button className="button sidebar-button "
                        onClick={(e) => {

                            e.currentTarget.classList.toggle('opened');
                            e.currentTarget.setAttribute('aria-expanded', e.currentTarget.classList.contains('opened'));

                            // Toggle side menu visibility
                            const sideMenu = document.getElementById('side-menu');
                            sideMenu.classList.toggle('visible');
                            const menuButton = document.getElementById('menu');
                            menuButton.classList.remove('opened');
                            menuButton.setAttribute('aria-expanded', 'false');
                        }}>
                        close
                    </button>
                </h2>
            </div>
            <div>

            </div>
        </div>

    );



}

export default Lmb;