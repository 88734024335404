// const config = require("../config/dbConfig"),
// Query = require('../config/Queries');
const api = "http://localhost:3305/login";
let user = [
	
];

const isLoggedIn = (info) =>{
 user = info;
}
//Will become Get Users or I will turn this into a dynamic running 
// function for all queries 
const login= async(username,password)=>{
   
  try { 
		console.log("Sending over to reciever:", {
			username: username,
			password: password,
			

		});

		fetch(api, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},body: JSON.stringify({
				username: username,
				password: password,
			})
		})
			.then((response) => {
				console.log("AUth Res:", response)
				// if (!response.ok && response.status !== 301 && response.status !== -1 ) {
				// 	throw new Error(`HTTP error! status: ${response.status}`);
				// }else if(response.status === 301 || response.status === -1){
				// 	console.log("User did sum idk Code: ", response.status)
				// 	return	response.status;
				// }
				return response.json();
			})
			.then((data) => {
				console.log("Auth data, after res:", data);

				if (data.Success) {

					//window.location.replace("http://localhost:3000/");
					console.log("User is nice")
					if(data.user){
						console.log(" %d User is nice",data.user)
						isLoggedIn(data.user)
					}
					// Handle successful login
					// Save user data to localStorage
					sessionStorage.setItem("user", JSON.stringify(data.Sesh));
					window.location.href = "/";
				} else {
					console.log(data);
				//	window.location.href = "/Login";
					// Handle login failure
				}
			})
			.catch((error) => {
				
				console.log("Error during fetch operation:", error );
				// Handle fetch errors
			});
	
    return ;
 } catch(Err){
    console.log(Err);
 }
}

module.exports= {
    login
}