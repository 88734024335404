import React from "react";
import links from "../Comps/links.js";
import {projs} from "../Comps/proj.js";



const LsbBar = () => {

    return(
        <div  className = "txts">
        <div  id = "bot">
            <h2>
                {/* <button className="button sidebar-button "  onClick={(e) =>links("me")}>
                    Journal
                </button> */}
                
                <button className="button sidebar-button" onClick={(e) =>links("contact")}>
                    Contact
                </button>

                <button className="button sidebar-button"  onClick={(e) =>projs("serverSphere")}>
                    Projects
                </button>
            </h2>
              </div>

          </div>  
        
    );



}

export default LsbBar;