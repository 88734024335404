import React, { useEffect } from 'react';
import gsap from 'gsap';

function Gsaps() {  

  useEffect(() => {
    const footer = document.querySelector('.site-projects');
    const header = document.querySelector('.site-header');
    const content = document.querySelector('.site-content');
    const full = document.querySelector('#full');
    const stack = document.querySelector('#stack');
    const dev = document.querySelector('#dev');
    const serv = document.querySelector('#serv');
    const cont = document.querySelector('#fullStackCont');
    const ch = document.querySelector('#chuch');
    const ws = document.querySelector('#WorkStats');

    gsap.to(footer, {
      scrollTrigger: {
        trigger: footer,

        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
      y: '-50vh',
    });
 
    gsap.to(header, {
      scrollTrigger: {
        trigger: footer,
        start: 'top 90%',
        end: 'bottom 80%',
        scrub: true,
      },
      y: 50,
      opacity: 0,
    });

    gsap.to(content, {
      scrollTrigger: {
        trigger: footer,
        start: 'top 90%',
        end: 'bottom 80%',
        scrub: true,
      },
      y: 150,
      opacity: 0,
    });

    gsap.fromTo(full, { y: 1200 }, {
      y: 0,
      scrollTrigger: {
        trigger: footer,
        start: 'top 75%',
        end: 'top 30%',
        scrub: true,
        pinSpacing: false,
      },
    });

    gsap.fromTo(stack, { y: 1000 }, {
      y: 0,
      scrollTrigger: {
        trigger: footer,
        start: 'top 75%',
        end: 'top 30%',
        scrub: true,
      },
    });

    gsap.fromTo(dev, { y: 800 }, {
      y: 0,
      scrollTrigger: {
        trigger: footer,
        start: 'top 75%',
        end: 'top 30%',
        scrub: true,
      },
    });   
     gsap.fromTo(serv, { y: 1000 }, {
      y: 0,
      x: 100,
      scrollTrigger: {
        trigger: footer,
        start: 'top 75%',
        end: 'top 30%',
       
        scrub: true,
      },
    });
 gsap.fromTo(ch, { y: 800, x: 3000 }, {
      y: 0,
      x: 0,
      scrollTrigger: {
        // markers: true,
        trigger: footer,
        start: 'top 75%',
        end: 'top 36%',
        scrub: true,
      },
    });
    gsap.fromTo(ws, { x: -1000 }, {
      
      x: 0,
      y:0,
      scrollTrigger: {
        trigger: footer,
        start: 'top 75%',
        end: 'top 36%',
        scrub: true,
      },
    });
  }, []);

  return null; // This component doesn't render anything
}

export default Gsaps;
