import React from "react";

const Repos = () => {

  const linkReps = (repName) => {

    if (repName != null) {
      window.open(`https://www.github.com/Esau4119/${repName}`, "_blank")
    }

  }

  return (


    <div id="repos">
      <div id="repoHolder">
        <div className="repo">
          <div className="repTitle txts">C/C++</div>
          <div className="repLinks">
            <div className="LinkHolder monst" onClick={() => linkReps("SilentFileSystem")}>
              <a href="">Linux File System Remake</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("SilentSimpleShell")}>
              <a href="">Linux CLI Commands Remake</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("SilentBufferIO")}>
              <a href="">Linux Buffer I/O system remake</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("CPP_Prac")} >
              <a href="">CPP Practice</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
          </div>
        </div>

        <div class="repo">
          <div class="repTitle txts">Java</div>
          <div className="repLinks">
            <div className="LinkHolder monst" onClick={() => linkReps("JavaMethods")} >
              <a href="">Java Methods</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("JavaSimpleServer")}>
              <a href="">Java Simple Servers</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("React-Java")}>
              <a href="">Java & React</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("JavaPrac")}>
              <a href="">Java Practice</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
          </div>
        </div>
        <div class="repo">
          <div class="repTitle txts ">Python</div>
          <div className="repLinks">
            <div className="LinkHolder monst" onClick={() => linkReps("PacmanAI")}>
              <a href="">Pacman AI</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("675DiscordBot")}>
              <a href="">Medical Database</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("Quant-Search")}>
              <a href="" >Quantum Search Algorithm</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("Quant-Circuits")}>
              <a href="">Quantum Circuits</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
          </div>
        </div>
        <div className="repo">
          <div className="repTitle txts">JavaScript</div>
          <div className="repLinks">
            <div className="LinkHolder monst" onClick={() => linkReps("317PhotoApp")}>
              <a href="">The lounge</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("TutorApp")}>
              <a href="">Tutor App</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("ServerSphere")}>
              <a href="">Server Sphere</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
            <div className="LinkHolder monst" onClick={() => linkReps("react-boot-intro")}>
              <a href="">First ever Repo</a>
              <img src={require("../Images/right-arrow.png")} id="" className='linkArrow' alt="loading..." />
            </div>
          </div>
        </div>

      </div>
    </div>
  );



}

export default Repos;