const projects = {
    serverSphere: {
      imgSrc: require("../Images/sphere/2shot.png"),
      extras:[
        require("../Images/sphere/2shot.png"),
        require("../Images/sphere/3hot.png"),
        require("../Images/sphere/messagesphere.png"),
        require("../Images/sphere/ranSphere.png"),
        require("../Images/sphere/Signup.png"),
      ],
      title: "ServerSphere",
      Role: "Backend-Lead",
      tools: "MongoDB, Express, React, Node",
      WID: "Given the task of making a server, I decided to plan on making a small app for our graduating class. The idea was to get everyone to leave a final message to us in a yearbook signing-style event. Either with anonymity (Username = RANDOM) or a custom Username when they signed up.  With a cloud database in mind, I decided to use MongoDB clusters for hosting. I made a REST API with Express that would connect to this cluster. This way we can store our users and messages for data retrieval. Having my front-end lead start up the React app, I created a base template for them. Having it send over the necessary data to my API. This increased our production time because all that was left was some basic CSS styling that was covered by the front-end team. ",
      description: "We made a simple app that stores users and messages. Visitors can enter any assortment of text into our database. Those who sign up can add a custom username. Admin will oversee the entirety of the database and make sure everything is in working order. "
    },    
    TheLounge: {
      imgSrc: require("../Images/lounge/4shot.png"),
      extras:[
        require("../Images/lounge/long.png"),
        require("../Images/lounge/1shot.png"),
        require("../Images/lounge/half3shot.png"),
        require("../Images/lounge/half3shot2.png"),
        require("../Images/lounge/post.png"),
        require("../Images/lounge/reg.png"),
      ],
      title: "The Lounge",
      Role: "FullStack",
      tools: "MySql, Express, HandleBars, Node",
      WID:"Starting with our front end, I created all of the necessary routes and HTML for this website. CSS layouts were the next target focus as we needed to have a theme implemented. The next part includes creating a REST API for a dedicated SQL database. This is where we created our entity relation diagram. After forward engineering our map into rebuildable code, we automated the build process for these tables. Allowing us to clear all of the data if needed and restart from a fresh database. User registration was another big focus, as middleware functions were created to handle any data that was making its way to the database. Including password encryption, user sessions, and images with thumbnails. Returning data was also added with a custom search for posts. These posts held a timestamp, author, image, comments, title, and description.  ",
      description: "The Louge is targeted as a mock social media app. Hosting a dark club room aesthetic, users can post pictures. Comments on posts are also possible when a user is registered. However, anyone can still view any post with all of its details. Including when the post was made, other users' comments, captions, and the post author. With the search function implemented, users can look for any post they want or view any post that is related to their inquiry."
    },    
    Cheep: {
      imgSrc: require("../Images/cheep/4shot.png"),
      extras:[
        require("../Images/cheep/shot1.png"),
        require("../Images/cheep/all.png"),
        require("../Images/cheep/tutorinfo.png"),
        require("../Images/cheep/files.png"),
        require("../Images/cheep/become.png"),
      ],
      title: "SFSU Tutor App",
      Role: "Front-End & Documentation",
      tools: "MySql, Express, React, Node, AWS (EC2)",
      WID: "Being on a five-member team, I was in charge of noting all of our technologies and developing several front-end components. Ending with a 100+ page document with all of our stages through building this app. Including App summaries, use cases, QA Test Plans, main data entities, functional requirements, a competitive analysis, and our high-level system architecture. ",
      description: "Gators Tutor is a user-friendly online tutoring platform exclusively designed for San Francisco State University (SFSU) students. Our primary goal is to empower SFSU students by granting them access to experienced tutors across various subjects. The distinctive feature of Gators Tutor is SFSU-specific search. For example, users searching for tutors on Gators Tutor can filter results by SFSU-specific classes, ensuring that they receive personalized support that aligns with their academic curriculum. We also empower SFSU students not only to seek academic assistance but also to become tutors themselves. Gators Tutor is the perfect place for any SFSU student seeking assistance in their classes or wanting to help others and earn extra money. Our user-friendly interface makes signing up as a tutor, searching for a tutor, or scheduling online tutoring sessions effortless."
     },    
    
    DeviceDriver: {
      imgSrc: require("../Images/linux/3shot.png"),
      extras:[
        require("../Images/linux/2shot.png"),
        require("../Images/linux/hex.png"),
        require("../Images/linux/run1.png"),
        require("../Images/linux/run2.png"),
        require("../Images/linux/run3.png"),
        require("../Images/linux/rn2.png"),
      ],
      title: "Device Driver",
      Role: "Builder",
      tools: "Virtual Box (VM), Ubuntu, C ",
      WID: "I started by creating the init function and preparing the file to be loaded. After checking /proc/devices for an available device number, I used this to register the device, setting up error handling to protect the kernel. Using mknod, I created the device and verified the module's load with dmesg and kernel log checks. Running the application, I confirmed success through printk messages in the logs. I then explored cdev to automate the process and implemented ioctl to move an integer back and forth, creating a header file with read, write, and a saluter function for the structs. While integer transfer was successful, I faced issues when trying to move a char array (string).",
      description: "Loadable Linux Device Driver with custom encryption. This was made to show us open, release, read, and write into a device driver. This can be later used for microcontrollers and other such devices. "
    },  
     Dbot: {
      imgSrc: require("../Images/bot/2shot.png"),
      extras:[
        require("../Images/bot/3shot.png"),
        require("../Images/bot/Disbot.png"),
        require("../Images/bot/disbot2.png"),
        require("../Images/bot/test.png"),
        require("../Images/bot/cmd1.png"),
        require("../Images/bot/cmd2.png"),
        require("../Images/bot/cmd3.png"),
      ],
      title: "Medical Database",
      Role: "FullStack",
      tools: "AWS, MySql, Python, Discord Development",
      WID: "Designed Entity Relation Map. Transferred into SQL to create an Entiy Relation Map. Forward Engineered to create useable and replicable data tables. Hosting this database with an AWS S3 bucket, I used Discord development to connect the server. There I was able to create a query with over 10+ commands. Which were later tested by our class grader. ",
      description: " Biotech Research Database was made to streamline collaboration and data management within the biotechnology industry. This database system aims to address the challenges faced by biotech researchers and organizations in managing their projects, data, and resources efficiently. It will serve as a central hub for researchers, labs, and stakeholders to collaborate, access vital information, and accelerate breakthroughs in biotechnology."
    },
     Pac: {
      imgSrc: require("../Images/pac/SCORES.png"),
      extras:[
        require("../Images/pac/all.png"),
        require("../Images/pac/big1.png"),
        require("../Images/pac/big2.png"),
        require("../Images/pac/bigwin.png"),
        require("../Images/pac/Search.png"),
        require("../Images/pac/wins.png"),
        require("../Images/pac/search2.png"),
        require("../Images/pac/win2.png"),
        require("../Images/pac/auto.png"),
      ],
      title: "Pacman AI",
      Role: "Algorithm Developer",
      tools: "Python and CSC188 UC Berkeley Project Set",
      WID: "During this AI course, we learned a lot about search algorithms. Our professor directed us to the UC Berkeley csc188 AI Pacman projects for our class assignments. There we implemented our own search algorithms to get our Pacman to successfully eat all of the nodes. Some parts required weights and custom heuristics, others were random actions that Pacman could take. These were all used to train our AI to become efficient at beating the game. ",
      description: " These projects cover a range of AI techniques, including state-space search algorithms (depth-first, breadth-first, uniform cost, and A*), probabilistic inference, and reinforcement learning. We apply these algorithms to solve various problems in the Pac-Man world, such as navigation and traveling salesman problems, and model Pac-Man as both an adversarial and stochastic search problem using multiagent minimax and expectimax algorithms. Additionally, the projects involve designing evaluation functions and implementing model-based and model-free reinforcement learning algorithms in environments like Gridworld, Pac-Man, and a simulated crawling robot."
    },
    // Add more projects here
  };

  const projs = (proj)=> {

 
    if(proj == "serverSphere"){
      window.location.href = `/Project?proj=${proj}`;
    }
    if(proj == "Dbot"){
      window.location.href = `/Project?proj=${proj}`;
    }    
    if(proj == "TheLounge"){
      window.location.href = `/Project?proj=${proj}`;
    }
    if(proj == "Cheep"){
      window.location.href = `/Project?proj=${proj}`;
    }
    if(proj == "DeviceDriver"){
      window.location.href = `/Project?proj=${proj}`;
    }
    if(proj == "Port"){
      window.location.href = `/Project?proj=${proj}`;
    }
    if(proj == "Pac"){
      window.location.href = `/Project?proj=${proj}`;
    }


}

export { projects, projs };
  //serverSphere,TheLounge,Cheep,DeviceDriver,Dbot