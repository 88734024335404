import emailjs from '@emailjs/browser';
import React, { Fragment, useRef, useState } from 'react';
export const SideContact = () => {
  const form = useRef();
  const [formSubmitted, setFormSubmitted] = useState(false); // State to track form submission

  const sendEmail = (e) => {
    e.preventDefault();

    if (e.target[0].value === "" || e.target[1].value === "" || e.target[2].value === "") {
      console.log("Empty fields");
    } else {
      console.log(e.target[0].value, e.target[1].value, e.target[2].value);
      emailjs
        .sendForm(
          process.env.REACT_APP_SERVICEKEY,
          process.env.REACT_APP_TEMPLATEKEY,
          form.current,
          process.env.REACT_APP_PUBLICKEY
        )
        .then(
          () => {
            console.log('SUCCESS!');
            setFormSubmitted(true); // Set formSubmitted to true on success
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
    }
  };

  return (
    <Fragment>
      <div id="side-contact" className="side-contact">
        <h2 className=' form__title'>contact me</h2>
        {formSubmitted ? ( // Conditional rendering based on form submission
          <div>

            <h3 className=' form__label  monst'>Thank you for sending a message...I'll get back to you ASAP!</h3>
            <div id="butt" className="form__label butt txts "
              onClick={(e) => {

                // Toggle side menu visibility
                const sideMenu = document.getElementById('side-contact');
                sideMenu.classList.toggle('visible');
              }}>
              Close
            </div>
          </div>

        ) : (
          <form ref={form} onSubmit={sendEmail}>
            <div id="formCont">
              <div className="form__group">
                <input className=" form__field monst" type="text" name="user_name" />
                <label className='form__label txts' htmlFor="">Name</label>
              </div>
              <div className="form__group">
                <input className=" form__field monst" type="email" name="user_email" />
                <label className='form__label txts' htmlFor="">Email</label>
              </div>
              <div className="form__group">
                <textarea className=" form__field TA " name="message" />
                <label className='form__label txts' htmlFor="">Message</label>
              </div>
              <div className="butt-holder ">
                <input className='closer form__label butt txts' type="submit" value="Send" />
                <div id="butt" className="form__label butt txts "
                  onClick={(e) => {

                    // Toggle side menu visibility
                    // Toggle side menu visibility
                    const sideMenu = document.getElementById('side-contact');
                    sideMenu.classList.toggle('visible');
                  }}>
                  cancel
                </div>

              </div>
            </div>
          </form>
        )}

      </div>

    </Fragment>
  );
};

export default SideContact;