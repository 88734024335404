const api = "http://localhost:3305/logout";


const logout = async () => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    try {
        const response = await fetch(api, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        });
     
        if (response.status) {
            console.log("User logged out successfully");
            await window.sessionStorage.clear();
		
			window.location.href = "/";

        } else {
            console.log("Failed to log out user", response.status);
        }
    } catch (error) {
        console.log("Error during fetch operation:", error);
    }
};

module.exports = {
    logout
};
