import React, { useEffect } from 'react';
import gsap from 'gsap';

const LoadingPage = () => {

  useEffect(() => {
    const preloader = document.querySelector('.preloader');
    const preloaderTimeline = gsap.timeline({
      onComplete: () => {
        preloader.style.display = 'none';
      }
    });

    preloaderTimeline.to(".preloader", {
      yPercent: -100,
      duration: 1.5,
      ease: "power4.inOut"
    });
  });

  return (
    <div className="preloader">
      <h1>Welcome</h1>
    </div>
  );
};

export default LoadingPage;