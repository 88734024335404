// const config = require("../config/dbConfig"),
// Query = require('../config/Queries');


const api = "http://localhost:3305/create";

//Will become Get Users or I will turn this into a dynamic running 
// function for all queries 
const search= async(username,password,email,cpassword)=>{
   
  try { 
		console.log("Sending over to reciever:", {
			email: email,
			password: password,
			username: username,
			cpassword: cpassword
		});

		fetch(api, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},body: JSON.stringify({
				username: username,
				email: email,
				password: password,
				cpassword: cpassword
			})
		})
			.then((response) => {
				console.log(response.status)
				if (response.status != 301 && response.status != -1) {
					console.log("ok but ", response.status)
					return	response.status;
				}else if(response.status == 301 || response.status == -1){
					console.log("User did sum idk Code: ", response.status)
					return	response.status;
				}else if(response.status == 403 ){
					console.log("User did sum idk Code: ", response.status)
					return	response.status;
				}else if(!response.ok ){
					
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				return response.json();
			})
			.then((data) => {
				console.log("Response data:", data);

				if (data != 301) {

					//window.location.replace("http://localhost:3000/");
					console.log("User is nice")
					
					// Handle successful login
					// Save user data to localStorage
						sessionStorage.setItem("user", JSON.stringify(data));
						window.location.href = "/login";
				} else {
					console.log("user did some", data);
					// Handle login failure
				}
			})
			.catch((error) => {
				
				console.log("Error during fetch operation:", error );
				// Handle fetch errors
			});
	
    return ;
 } catch(Err){
    console.log(Err);
 }
}

module.exports= {
    search
}