import React, { Fragment } from 'react';
import logout from "../routes/logout.js";
import Lmb from "../Comps/menuBar.js";

import links from "../Comps/links.js";
import {projs} from "../Comps/proj.js";
import SideContact from './contactMenu.js';
function Nav() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    let isLoggedIn = false

    if (user) {
        // console.log(user)
        isLoggedIn = true;
    } else {
        isLoggedIn = false
    }


    /*
     * Toggles on and off the 'active' class on the menu
     * and the toggler button.
     */

    const clicked = () => {


        // if(user == false){
        //     user= true;
        //     isLoggedIn = true;
        // }else{
        //     user= false;

        // }

        console.log("agent P says hi");
    };
    const logO = () => {
        logout.logout();
        console.log("agent P says hi");
    };
    return (
        <Fragment>
             <Lmb />
            <nav>
                <div href="/" id="bot2">
                    <div id="namelg" className='' >
                        <a href="/">
                            <h2>
                                EBM.
                            </h2>
                        </a>
                    </div>
                </div>

                {isLoggedIn ? (
                    <div className="navGrid">
                        {/*                                         
                                        <a className='txts ' href="/AboutMe">
                                        About              
                                    </a>
                                    <br/>
                                    <a className='txts' >
                                        Home        
                                    </a> */}

                        <a className="txts interactable" onClick={(e) => (logO())} >
                            <h2>{user[1]}</h2>

                        </a>
                    </div>
                ) : (
                    <div className="menu-container">
                        {/* <a className="ts tx account-link" href="/AboutMe">
                            <h2 className="">
                                Journal
                            </h2>
                        </a> */}
                        <div id="icon">
                                <button
                                    className="menu"
                                    id='menu'
                                    onClick={(e) => {
                                        e.currentTarget.classList.toggle('opened');
                                        e.currentTarget.setAttribute('aria-expanded', e.currentTarget.classList.contains('opened'));

                                        // Toggle side menu visibility
                                        const sideMenu = document.getElementById('side-menu');
                                        sideMenu.classList.toggle('visible');
                                    }}
                                    aria-label="Main Menu"
                                    style={{ backgroundColor: 'transparent', border: 'none' }}
                                >
                                    <svg width="50" height="100" viewBox="0 0 100 100">
                                        <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                                        <path className="line line2" d="M 20,50 H 80" />
                                        <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                                    </svg>
                                </button>
                            </div>

       
                    </div>



                )}


            </nav>

        </Fragment>
    )
}


export default Nav;
