import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import flower2 from '../models/wado.glb';

// Model Component
function Model() {
  const gltf = useGLTF(flower2);
  const modelRef = useRef();

  // Use useFrame to rotate the model
  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y -= 0.001; // Adjust this value for rotation speed
    }
  });

  return <primitive ref={modelRef} object={gltf.scene} scale={[.75, .75, .75]} position={[2, -2.5, -1]} />;
}

// Scene Component
function Scene() {
  return (
    <>
      {/* Strong ambient light for even lighting without shadows */}
      <ambientLight color={'#ffffff'} intensity={5} />

      {/* Front directional light */}
      <directionalLight position={[5, 10, 5]} intensity={10} castShadow={false} />
      
      {/* Back directional light */}
      <directionalLight position={[-5, -10, -5]} intensity={10} castShadow={false} />

      {/* Front spotlight */}
      <spotLight position={[0, -10, 10]}intensity={10} castShadow={false} />

      {/* Back spotlight */}
      <spotLight position={[0, 10, -10]}  intensity={10} castShadow={false} />

      <Model />
    </>
  );
}


// Main Render Component
function Flower2() {
  return (
    <Canvas id="flower2"camera={{ position: [3, 2, -3], fov: 75 }}>
      <Scene />
      {/* OrbitControls to enable camera movement */}
      {/* <OrbitControls enableDamping dampingFactor={0.5} enableZoom={true} enablePan={true} /> */}
    </Canvas>
  );
}

export default Flower2;
