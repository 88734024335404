import React, { Fragment, useEffect, useRef } from 'react';
import Nav from "../Comps/Nav.js";
import LsbBar from "../Comps/lsbBar.js";
import Footer from "../Comps/footer.js";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { Flip } from "gsap/Flip";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { EaselPlugin } from "gsap/EaselPlugin";
import { TextPlugin } from "gsap/TextPlugin";
import SideContact from "../Comps/contactMenu.js";

import Lenis from 'lenis'
gsap.registerPlugin(Flip, ScrollTrigger, ScrollToPlugin, Draggable, EaselPlugin, TextPlugin);

function AboutMe() {

    document.addEventListener('scroll', function () {
        const sections = document.querySelectorAll('section'); // Includes all sections
        const nodes = document.querySelectorAll('.timeline-node');
        let scrollPosition = window.scrollY + window.innerHeight / 1.5;

        sections.forEach((section, index) => {
            const sectionTop = section.getBoundingClientRect().top + window.scrollY;
            const sectionBottom = sectionTop + section.offsetHeight;

            if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                nodes.forEach(node => node.style.backgroundColor = 'black'); // Reset all nodes
                nodes[index].style.backgroundColor = 'white'; // Highlight current node
            }
        });
    });

    return (
        <Fragment>
            <SideContact />
            <div className="Main-Cont">
                <header className='site-header'>
                    <Nav />
                </header>
                <aside className='site-lsb'>
                    <LsbBar />
                </aside>
                <div className='site-content'>
                    <div id="about-title">
                        <h1 className='lineUp' > The Developer</h1>
                    </div>
                    <div id="AboutContainer" >



                        <div id="About-Pic">

                            <div id="About-Pic">

                                <img src={require("../Images/propic3.png")} id="" className='lineUp' alt="loading..." />
                                <img src={require("../Images/sig.svg").default} id="sig" className='lineUp' alt="loading..." />
                            </div>

                        </div>
                        <div id="About-description">
                            <div className="timeline">
                                <div className="timeline-node" id="node1"></div>
                                <div className="timeline-node" id="node2"></div>
                                <div className="timeline-node" id="node3"></div>
                            </div>

                            <div className="content lineUp">

                                <section id="story">
                                    <h2>The Story</h2>
                                    <p className='monst'>Learned about programming in middle school and decided
                                        I wanted to work with technology.
                                        So my journey to become a software developer began...</p>
                                </section>

                                <section id="present">
                                    <h2>Present</h2>
                                    <p className='monst'>After leaving Los Angeles to study in San Francisco (SFSU)
                                        for my Bachelors in Computer Science,
                                        I learned what it takes to become a successful developer.
                                        Now I continue to learn and gain hands on experience with
                                        all types of technologies.
                                    </p>
                                </section>

                                <section id="future">
                                    <h2>Future</h2>
                                    <p className='monst'>The main goal is to become a well rounded full stack developer. Achieving this through growth and professional experience!
                                    </p>
                                </section>

                            </div>


                        </div>
                        <div id="About-me" className='lineUp'>

                        </div>
                    </div>
                </div>
                <aside className='site-footer'>
                    <Footer />
                </aside>
            </div>
        </Fragment>
    );
}

export default AboutMe;
